.spacious-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  @include media-breakpoint-up(sm) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.badge {
  background-color: $blue-50;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.card {
  &.profile-card {
    border-radius: 12px !important;
    @include media-breakpoint-down(md) {
      border-radius: 0 !important;
    }
  }
}
.error-text {
  color: #f04438;
}
.padding-bottom {
  padding-bottom: 6rem;
}

// recruiter-section

.recruiter-spacious-section{
  padding-top: 2rem;
  padding-bottom: 4rem;
  @include media-breakpoint-down(md) {
    padding: 1rem .25rem 0px .25rem;
  }
}

.recruiter-sign-up-back-button{
  top: 24px;
  @include media-breakpoint-down(md){
    top: 48px;
 }
}

.candidate{
  & div {
    & iframe{
     left: 8px !important;
     right: 0 !important;
    }
  }
}
.candidate-title{
  font-size: 20px;
  color:"#6941C6";
}
.candidate-common-text{
  color: #475467 ;
  font-weight: 500;
}
.candidate-search-details{
  color: #667085;
}

.tooltip-inner{
  text-align: start !important;
  max-width: 240px !important;
  @include media-breakpoint-down(sm) {
    max-width: 100% !important;
  }
}
ul{
  li{
    list-style: none;
  }
}