.pagination {
  justify-content: center;
  padding-top: 20px;
  position: relative;
}

.activePage {
  color: #1d2939;
  background-color: #eaecf0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
}
.paginationBorder {
  border: none;
  @include media-breakpoint-down(md) {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}
.left-arrow {
  position: absolute;
  left: 0;
}
.right-arrow {
  position: absolute;
  right: 0;
}
